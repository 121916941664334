// ICONS
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0");

// BOOTSTRAP
@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

// COLOURS
$main-color: #180f4d;

// STYLING
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

// RESPONSIVE WIDTH
// RESPONSIVE
$laptop-l-width: 1440px;
$laptop-m-width: 1390px;
$laptop-s-width: 1250px;
$laptop-width: 1024px;
$tablet-l-width: 930px;
$tablet-m-width: 830px;
$tablet-width: 769px;
$mobile-xl-width: 686px;
$mobile-l-width: 426px;
$mobile-m-width: 376px;
$mobile-s-width: 321px;

@mixin laptop-l {
  @media (max-width: $laptop-l-width) {
    @content;
  }
}
@mixin laptop-m {
  @media (max-width: $laptop-m-width) {
    @content;
  }
}
@mixin laptop-s {
  @media (max-width: $laptop-s-width) {
    @content;
  }
}
@mixin laptop {
  @media (max-width: $laptop-width) {
    @content;
  }
}
@mixin tablet-l {
  @media (max-width: $tablet-l-width) {
    @content;
  }
}
@mixin tablet-m {
  @media (max-width: $tablet-m-width) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: $tablet-width) {
    @content;
  }
}
@mixin mobile-xl {
  @media (max-width: $mobile-xl-width) {
    @content;
  }
}
@mixin mobile-l {
  @media (max-width: $mobile-l-width) {
    @content;
  }
}
@mixin mobile-m {
  @media (max-width: $mobile-m-width) {
    @content;
  }
}
@mixin mobile-s {
  @media (max-width: $mobile-s-width) {
    @content;
  }
}

@keyframes skeleton {
  0% {
    background-color: #f5f5f5;
  }
  100% {
    background-color: rgb(116, 115, 115);
  }
}
