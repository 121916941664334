// @import "../../node_modules/bootstrap/scss/bootstrap";
@import "styles";
@import "components";

// mixin
@mixin line-clamp() {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// HERO SECTION
.hero {
  &__carousel {
    &__list {
      &__item {
        width: 100%;
        height: 70vh;
        &__img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          filter: brightness(0.33);
        }
        &__text-box {
          margin-top: 13rem;
          @include tablet-l {
            margin-top: 11rem;
          }
          @include mobile-m {
            margin-top: 10rem;
          }
          h2 {
            font-size: 2.4rem;
            text-align: center;
            margin-bottom: 1rem;
            font-weight: 600;
            // LINE CLAMP
            @include line-clamp();
            -webkit-line-clamp: 2;
            @include tablet-l {
              font-size: 1.8rem;
            }
            @include mobile-xl {
              font-size: 1.6rem;
              -webkit-line-clamp: 3;
            }
            @include mobile-m {
              font-size: 1.2rem;
              margin-bottom: 0.5rem;
              -webkit-line-clamp: 5;
            }
          }
          a {
            text-decoration: none;
            color: #fff;
            font-size: 20px;
            font-weight: 400;
            @include mobile-xl {
              font-size: 15px;
            }
            @include mobile-m {
              font-size: 12px;
            }
          }
        }
      }
      &__placeholder {
        height: 500px;
        .placeholder-glow {
          height: 100%;
          span {
            height: 100%;
          }
        }
      }
    }
  }
}

// NEWS SECTION
.news {
  display: flex;
  flex-direction: column;
  padding: 10vh 12.5vw;
  &__title {
    h4 {
      color: $main-color;
      font-size: 25px;
      font-weight: 400;
      margin-bottom: 12px;
    }
    hr {
      border: 1px solid #180f4d;
      opacity: 100;
    }
  }
  &__list {
    display: grid;
    grid-auto-columns: calc((100% / 4));
    grid-auto-flow: column;
    gap: 8px;
    margin: 50px 0 40px 0;
    @include laptop {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 15px;
      &::-webkit-scrollbar {
        height: 4px;
        cursor: pointer;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #180f4d;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
    }
    @include tablet {
      display: flex;
      flex-direction: column;
      margin: 30px 0 30px 0;
    }
    &__item {
      background-color: $main-color;
      border-radius: 5px;
      text-decoration: none;
      height: 97%;
      @include laptop {
        height: 375px;
      }
      @include tablet {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 195px;
      }
      &:hover {
        .news__list__item__img-container {
          img {
            transform: scale(1.1);
          }
        }
      }
      &__img-container {
        width: 100%;
        height: 40%;
        border-radius: 5px 5px 0 0;
        overflow: hidden;
        @include laptop {
          height: 36%;
        }
        @include tablet {
          width: 25%;
          height: 100%;
          border-radius: 5px 0 0 5px;
        }
        img {
          transition: 0.5s;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      &__text {
        padding: 15px 20px;
        @include tablet {
          width: 75%;
        }
        &__title {
          margin-bottom: 18px;
          &__new-title {
            color: #fff;
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 7px;
            // LINE CLAMP
            @include line-clamp();
            -webkit-line-clamp: 3;
            @include laptop {
              font-size: 18px;
            }
            @include mobile-xl {
              font-size: 14px;
            }
          }
          &__date {
            color: #bbb;
            font-size: 10px;
            font-weight: 600;
            @include laptop {
              font-size: 12px;
            }
            @include mobile-xl {
              font-size: 10px;
            }
          }
        }
        &__description {
          color: #cdcdcd;
          font-size: 12px;
          font-weight: 500;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          @include laptop {
            font-size: 14px;
          }
          @include tablet {
            font-size: 12px;
          }
          @include mobile-xl {
            font-size: 11px;
          }
        }
      }
    }
    //PLACEHOLDER
    &__placeholder-item {
      height: 386px;
      @include tablet {
        display: flex;
        align-items: start;
        gap: 20px;
        height: 150px;
      }
      &__img {
        height: 40%;
        @include tablet {
          width: 25%;
          height: 146px;
        }
        p {
          height: 100%;
          span {
            height: 100%;
          }
        }
      }
      &__text {
        padding: 15px 0;
        @include tablet {
          width: 75%;
          height: 100%;
          padding: 0;
        }
        &__heading {
          p {
            span {
              height: 20px;
            }
          }
        }
        &__para {
          p {
            span {
              height: 13px;
            }
          }
        }
      }
    }
  }
  &__read-more {
    color: $main-color;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      color: #20136a;
    }
  }
}

// INTRODUCTION STYLE 1 SECTION
.introduction1 {
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  padding: 10vh 12.5vw;
  background: $main-color;
  @include tablet {
    flex-direction: column;
  }
  &__text {
    width: 48%;
    @include tablet {
      width: 100%;
    }
    &__title {
      color: #fff;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    &__content {
      p {
        color: #f8f8f8;
        font-family: Inter;
        font-size: 15px;
        font-weight: 300;
        line-height: 27px;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        @include mobile-l {
          font-size: 13px;
        }
      }
    }
  }
  &.img-1 {
    .introduction1__grid-box {
      width: 52%;
      height: 100%;
      @include tablet {
        width: 100%;
      }
      .img {
        width: 100%;
        height: clamp(25vh, 40vh, 50vh);
        object-fit: cover;
      }
    }
  }
  &.img-2 {
    .introduction1__grid-box {
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      width: 52%;
      height: 100%;
      row-gap: 15px;
      @include tablet {
        width: 100%;
      }
      .img {
        width: 100%;
        height: clamp(10vh, 20vh, 30vh);
        object-fit: cover;
      }
    }
  }
  &.img-3 {
    .introduction1__grid-box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      width: 52%;
      gap: 15px;
      @include tablet {
        width: 100%;
      }
      .img {
        width: 100%;
        height: clamp(10vh, 16vh, 22vh);
        object-fit: cover;
        @include tablet {
          height: 10vh;
        }
        @include mobile-l {
          height: 8vh;
        }
      }
      &__img3 {
        grid-column: 1/3;
      }
    }
  }
}

// INTRODUCTION STYLE 2 SECTION
.introduction2 {
  padding: 10vh 12.5vw;
  &__title {
    margin-bottom: 50px;
    h4 {
      color: $main-color;
      font-size: 25px;
      font-weight: 400;
      margin-bottom: 12px;
    }
    hr {
      border: 1px solid $main-color;
      opacity: 100;
    }
  }
  &__content {
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: center;
    @include tablet {
      flex-direction: column;
    }
    &__img-container {
      width: 37%;
      @include tablet {
        width: 100%;
      }
      &.img-1 {
        img {
          width: 100%;
          height: clamp(20vh, 25vh, 35vh);
          object-fit: cover;
          border-radius: 1px;
          @include tablet {
            height: 9vh;
          }
        }
      }
      &.img-2 {
        display: grid;
        grid-template-rows: 1fr 1fr;
        row-gap: 15px;
        img {
          width: 100%;
          height: clamp(10vh, 15vh, 20vh);
          object-fit: cover;
          border-radius: 1px;
          @include tablet {
            height: 7vh;
          }
        }
      }
      &.img-3 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 15px;
        column-gap: 15px;
        img {
          width: 100%;
          height: clamp(10vh, 13vh, 16vh);
          object-fit: cover;
          border-radius: 1px;
          @include tablet {
            height: 7vh;
          }
          &.img3 {
            grid-column: 1/3;
          }
        }
      }
    }
    &__text {
      width: 63%;
      @include tablet {
        width: 100%;
      }
      p {
        color: $main-color;
        font-size: 15px;
        font-weight: 300;
        line-height: normal;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        @include mobile-l {
          font-size: 13px;
        }
      }
    }
  }
}

// PLACEHOLDERS FOR INTRODUCTION SECTION
.strapi-content-container {
  .placeholder1,
  .placeholder2 {
    padding: 0 12.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    gap: 5%;
    @include tablet-l {
      flex-direction: column;
    }
    .img {
      width: 40%;
      height: 200px;
      margin-bottom: 0;
      @include tablet-l {
        width: 100%;
        margin-bottom: 10px;
      }
      .col-12 {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      width: 55%;
      @include tablet-l {
        width: 100%;
      }
      .sub-heading {
        margin-bottom: 10px;
      }
      .heading {
        margin-bottom: 10px;
        height: 30px;
        .placeholder {
          height: 100%;
        }
      }
    }
  }
  .placeholder2 {
    flex-direction: row-reverse;
    @include tablet-l {
      flex-direction: column;
    }
  }
}

// PARTNER SECTION
@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
.partner {
  padding: 10vh 12.5vw;
  background: $main-color;
  &__title {
    color: #fff;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 60px;
    text-align: center;
  }
  &__list {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    &::before,
    &:after {
      position: absolute;
      top: 0;
      width: 50px;
      height: 100%;
      content: "";
      z-index: 2;
    }
    &:before {
      left: 0;
      background: linear-gradient(to left, rgba(255, 255, 255, 0), $main-color);
    }
    &:after {
      right: 0;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        $main-color
      );
    }
    &:hover .partner__list__slide {
      animation-play-state: paused;
    }
    &__slide {
      display: inline-block;
      animation: 20s slide infinite linear;
      img {
        margin: 0 25px;
      }
    }
  }
}

// ACCORDION SECTION
.faqs {
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 10vh 12.5vw;
  @include tablet-l {
    display: grid;
    row-gap: 15px;
  }
  &__text {
    &__text-box {
      width: 25rem;
      margin-bottom: 30px;
      @include laptop-s {
        width: 22rem;
      }
      @include mobile-xl {
        width: 17rem;
      }
      &__sub-title {
        color: #939393;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
        @include mobile-xl {
          font-size: 17px;
        }
      }
      &__title {
        color: #180f4d;
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      &__sub-text {
        color: #939393;
        font-size: 20px;
        font-weight: 400;
      }
    }
    &__img-container {
      img {
        width: 300px;
        height: 80px;
        object-fit: cover;
        margin-bottom: 10px;
        @include tablet-l {
          width: 100%;
          height: 50px;
        }
      }
    }
  }
  &__question-list {
    box-shadow: 0px 8px 6px 0px #d2d2d2;
    border-radius: 5px;
    &__item {
      background: #fff;
      padding: 20px 25px;
      cursor: pointer;
      border-radius: 5px;
      svg {
        width: 30px;
        height: 30px;
        @include mobile-xl {
          width: 20px;
          height: 20px;
        }
      }
      &.active {
        .faqs__question-list__item__question__caret-down {
          transform: rotate(180deg);
        }
        .faqs__question-list__item__answer {
          max-height: 300px;
          padding-top: 10px;
        }
      }
      &__question {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        h4 {
          color: #565656;
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 0;
          @include mobile-xl {
            font-size: 15px;
          }
        }
        &__caret-down {
          transition: 0.3s;
          width: 15px;
        }
      }
      &__answer {
        margin-bottom: 0;
        max-height: 0;
        overflow: hidden;
        transition: 0.3s linear;
      }
    }
  }
}
