@import "styles";

// SUBNAV OWN STYLE
$subnav-color: #f5f5f5;

.subnav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12.5vw;
  background: $subnav-color;
  width: 100%;
  @include tablet {
    display: grid;
    padding: 0;
  }
  &__dropdown-btn {
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 15px 9.5vw;
    cursor: pointer;
    @include tablet {
      display: flex;
      &.active {
        padding: 15px 30px;
        .top {
          transform: rotate(45deg) translateY(10px);
        }
        .middle {
          opacity: 0;
        }
        .bottom {
          transform: rotate(-45deg) translateY(-10px);
        }
      }
      &__bar {
        width: 20px;
        border: 1px solid $main-color;
        transition: 0.3s ease-in-out;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__btns-container {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding-left: 0;
    @include tablet {
      display: none;
      &.display {
        display: block;
        width: 100vw;
      }
    }
    &__list {
      position: relative;
      display: flex;
      align-items: center;
      padding: 15px 30px;
      gap: 10px;
      transition: 0.2s;
      &.hidden {
        display: none;
      }
      @include tablet {
        padding: 15px 9.5vw;
        justify-content: center;
      }
      &:hover {
        background-color: #dedede;
      }
      &.dropdown-link {
        .subnav__btns-container__list__link__dropdown-icon {
          &.rotate {
            transform: rotate(180deg);
          }
        }
      }
      &__link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #000000;
        svg {
          width: 24;
          height: 24;
        }
        @include laptop {
          font-size: 14px;
          svg {
            width: 20;
            height: 20;
            @include mobile-xl {
              width: 16px;
              height: 16px;
            }
          }
        }
        @include mobile-xl {
          font-size: 12px;
        }
        &:hover {
          color: #000;
        }
        &__icon {
          @include tablet {
            display: none;
          }
        }
        &__text {
          display: none;
          @include tablet {
            display: block;
            margin-bottom: 0;
          }
        }
      }
      &__dropdown {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        display: none;
        z-index: 999;
        padding-left: 0;
        &__link {
          text-decoration: none;
          padding: 15px;
          color: #000;
          background-color: rgb(227, 227, 227);
          @include laptop {
            font-size: 14px;
          }
          @include tablet {
            text-align: center;
          }
          @include mobile-xl {
            font-size: 12px;
          }
          &:hover {
            color: #000;
            background-color: #e9e9e9;
          }
        }
        &.display {
          display: grid;
        }
      }
    }
  }
}

.dropdown-link {
  &:hover {
    background-color: #dedede;
    .subnav__btns-container__list__link__dropdown-icon {
      transform: rotate(180deg);
    }
    .subnav__btns-container__list__dropdown {
      display: grid;
    }
  }
}

.hidden {
  display: none;
}
.search-box {
  #search-box-input {
    border: none;
    border-bottom: 0.3px solid $main-color;
    background-color: #f5f5f5;
    &:focus {
      outline: none;
    }
  }
  button {
    border: none;
    border-bottom: 0.3px solid $main-color;
    background-color: #f5f5f5;
    position: relative;
    left: -5px;
    span {
      font-size: 20px;
    }
  }
  .search-result {
    z-index: 999;
    position: absolute;
    background-color: #f5f5f5;
    width: 250px;
    display: none;
    &.active {
      display: block;
    }
    ul {
      margin-top: 0.5rem;
      margin-bottom: 0;
      list-style: none;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        padding: 10px;
        .hidden {
          display: none;
        }
        a {
          text-decoration: none;
          color: black;
          h3 {
            color: grey;
            margin-top: 10px;
            font-size: 15px;
          }
        }
        &:hover {
          background-color: #dedede;
        }
      }
    }
  }
}
@media (max-width: 1160px) {
  .subnav {
    padding: 0 2vw;
  }
}

@media (max-width: 770px) {
  .subnav {
    justify-content: center;
    .search-box {
      display: none;
      &.display {
        display: flex;
        justify-content: center;
        padding: 5px 0 15px 0;
        font-size: 14px;
      }
    }
  }
}
