@import "styles";

.footer {
  color: #fff;
  background-color: $main-color;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 9vh 10.5vw 6vh 10.5vw;
  gap: 2vw;
  margin-top: 50px;
  width: 100%;
  @include tablet-m {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 4vh;
    padding: 9vh 9.5vw;
  }
  @include mobile-m {
    padding: 9vh 5.5vw;
  }
  @include mobile-s {
    padding: 9vh 4.5vw;
  }
  &__main {
    img {
      margin-bottom: 35px;
      @include laptop-s {
        width: 20vw;
        margin-bottom: 3vh;
      }
      @include tablet-m {
        width: 286px;
      }
    }
    &__title-container {
      &__title {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 6px;
        @include laptop-s {
          font-size: 1.07vw;
        }
        @include tablet-m {
          font-size: 15px;
        }
      }
      &__sub-title {
        font-size: 14px;
        font-weight: 300;
        @include laptop-s {
          font-size: 1vw;
        }
        @include tablet-m {
          font-size: 14px;
        }
      }
    }
  }
  &__contacts {
    h3 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 25px;
      @include laptop-s {
        font-size: clamp(15px, 1vw, 17px);
        margin-bottom: 3vh;
      }
    }
    &__list {
      padding: 0;
      &__item {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 15px;
        svg {
          width: 28;
          height: 29;
          @include tablet-m {
            width: 20;
            height: 19;
          }
        }
        .text {
          margin: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        h6 {
          font-size: 16px;
          font-weight: 400;
          @include laptop-s {
            font-size: 13px;
          }
          @include tablet-m {
            font-size: 16px;
          }
        }
        .media {
          &--facebook {
            display: flex;
            gap: 1vw;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            text-decoration: none;
          }
        }
      }
    }
  }
  &__details {
    display: flex;
    flex-direction: column;
    &__title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 25px;
    }
    &__map {
      @include laptop-s {
        width: 200px;
        height: 120px;
      }
      @include tablet-m {
        width: 300px;
        height: 150px;
      }
      @include mobile-m {
        width: 100%;
        height: 200px;
      }
    }
  }
}
.codeup1 {
  text-align: center;
  background-color: $main-color;
  padding-bottom: 27px;
  p {
    margin-bottom: 0;
    color: #696969;
    font-size: 15px;
    @include laptop {
      font-size: 11px;
    }
    @include mobile-l {
      font-size: 10px;
    }
  }
}
@media (max-width: 671px) {
  .footer {
    flex-direction: column;
    display: flex;
  }
}
