@import "styles";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $main-color;
  padding: 4.2vh 12.5vw;
  width: 100%;
  @include tablet {
    padding: 3.5vh 9.5vw;
  }
  &__cfe-logo {
    @include tablet {
      width: 26vw;
    }
  }
  &__logo-container {
    display: flex;
    align-items: center;
    gap: 45px;
    &__neu-logo {
      @include tablet {
        width: 10vw;
      }
    }
    &__fbm-logo {
      @include tablet {
        width: 14vw;
      }
    }
  }
}

// SUBNAV
body {
  margin: 0;
}
header {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 200px;
  flex-direction: column;
  .first-h {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    background-color: #180f4d;
    .logos {
      display: flex;
      align-items: center;
      width: 75%;
      height: 100%;
      .cfelogo {
        color: #ffff;
        gap: 20px;
        display: flex;
        align-items: center;
        height: 100%;
        width: 25%;
        font-size: 0.7rem;
        flex-grow: 1;
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        h1 {
          margin: 0;
        }
      }
      .otherlogo {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        .fbmlogo {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 10px;
        }
      }
    }
  }
  nav {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #f5f5f5;
    .navbuttons {
      display: flex;
      align-items: center;
      width: 75%;
      height: 100%;
      .button-dropdown {
        display: flex;
        text-align: center;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
        margin-right: 5px;
        height: 50px;
        background-color: #f5f5f5;
        flex-grow: 0;
        a {
          display: flex;
          position: relative;
          top: 16px;
          left: 10px;
          text-decoration: none;
          color: black;
        }
        &:hover {
          background-color: #0f080882;
        }
        .more {
          width: 100%;
          position: relative;
          top: 26px;
          left: -10px;
          display: none;
          a {
            top: 0px;
            left: 0px;
          }
        }
        &:hover {
          .more {
            display: block;
          }
        }
        .button {
          width: 100%;
        }
      }
      .button {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        height: 50px;
        background-color: #f5f5f5;
        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: black;
        }
        &:hover {
          background-color: #0f080882;
        }
      }
    }
  }
}
